<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-col cols="6">
      <v-card class="px-5 py-5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="6">
              <v-file-input
                label="Upload firebase export file"
                @change="loadTextFromFile"
              ></v-file-input>
            </v-col>
            <v-col>
              <v-progress-circular
                v-if="isLoadingFile"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-subheader>Filters</v-subheader>
          <v-row>
            <v-col cols="6">
              <v-select
                :items="allProgrammeTitles"
                v-model="selectedProgramme"
                label="Specific programme"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="selectedProgramme"
                label="Specific programme"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="4">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFrom"
                    label="Period start date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFrom"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" sm="6" md="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateTo"
                    label="Period end date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateTo"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="applyFilters"
          >
            Apply Filters
          </v-btn>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="printDRStats"
          >
            Print DR stats
          </v-btn>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="printStats"
          >
            Print stats
          </v-btn>
        </v-form>
      </v-card>
    </v-col>
    <v-row>
      <v-col cols="6" sm="4" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-account"
          title="Unique users"
          v-if="hasLoadedFile"
          :value="stats.totals.numUniqUsers"
        />
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <base-material-stats-card
          color="red"
          icon="mdi-sofa"
          title="Rooms"
          v-if="hasLoadedFile"
          :value="stats.totals.numRooms"
        />
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <base-material-stats-card
          color="teal"
          icon="mdi-keyboard"
          title="Messages"
          v-if="hasLoadedFile"
          :value="stats.totals.numMessages"
        />
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <base-material-stats-card
          color="amber"
          icon="mdi-video"
          title="Total watchtime"
          v-if="hasLoadedFile"
          :value="stats.totals.watchTime"
        />
      </v-col>
    </v-row>
    <rooms-and-users-line-chart
      v-if="hasLoadedFile"
      :data="stats.maps.roomsByDayMap"
    />
    <v-row>
      <v-col cols="12" lg="6">
        <rooms-by-hour-chart
          v-if="hasLoadedFile"
          :data="stats.maps.roomCreatedHourMap"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <rooms-by-watchtime-chart
          v-if="hasLoadedFile"
          :data="stats.maps.watchTimeMap"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <messages-by-player-time-chart
          v-if="hasLoadedFile"
          :data="stats.maps.messagesByPlayerTimeMap"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RoomsAndUsersLineChart from "../components/charts/RoomsAndUsersLineChart.vue";
import RoomsByHourChart from "../components/charts/RoomsByHourChart.vue";
import RoomsByWatchtimeChart from "../components/charts/RoomsByWatchtimeChart.vue";
import StatisticUtils from "../../../statistics/StatisticUtils";
import Vue from "vue";
import moment from "moment";
import MessagesByPlayerTimeChart from "../components/charts/MessagesByPlayerTimeChart.vue";

export default Vue.extend({
  name: "DashboardPagesCharts",
  components: {
    RoomsAndUsersLineChart,
    RoomsByHourChart,
    RoomsByWatchtimeChart,
    MessagesByPlayerTimeChart,
  },
  data() {
    return {
      selectedProgramme: "",
      statUtils: null,
      valid: true,
      rawData: null,
      hasLoadedFile: false,
      isLoadingFile: false,
      stats: {},
      menu1: false,
      menu2: false,
      dateFrom: null,
      dateTo: null,
      allProgrammeTitles: [],
    };
  },
  mounted() {
    this.statUtils = new StatisticUtils();
  },
  methods: {
    loadTextFromFile(ev) {
      console.log("Parsing started", ev);
      const file = ev;
      const reader = new FileReader();
      this.hasLoadedFile = false;
      this.isLoadingFile = true;

      reader.onload = (e) => {
        this.rawData = JSON.parse(e.target.result);

        // Load in the raw data (only do this once)
        this.statUtils.parseRawData(this.rawData);

        // Then upload the parsed data
        // this.statUtils.uploadJson();

        // Get the stats based on filter data
        const dateLowerBound = new Date("08-26-1990 00:01"); // Thursday morning
        const dateUpperBound = new Date("09-08-2100 23:59"); // Wednesday evening
        this.stats = this.statUtils.getAggregatedStatistics(
          dateLowerBound,
          dateUpperBound,
          ""
        );

        this.allProgrammeTitles = Object.values(
          this.stats.maps.programTitleMap
        ).map((e) => e.title);

        this.hasLoadedFile = true;
        this.isLoadingFile = false;
      };
      reader.readAsText(file);
    },
    applyFilters() {
      const parsedDateFrom = moment(this.dateFrom, "YYYYMMDD").toDate();
      parsedDateFrom.setHours(0, 0, 1); // Whole start date
      const parsedDateTo = moment(this.dateTo, "YYYYMMDD").toDate();
      parsedDateTo.setHours(23, 59, 1); // Whole end date
      console.log(parsedDateFrom, parsedDateTo, this.selectedProgramme);

      this.isLoadingFile = true;
      this.hasLoadedFile = false;
      this.stats = this.statUtils.getAggregatedStatistics(
        parsedDateFrom,
        parsedDateTo,
        this.selectedProgramme
      );
      this.isLoadingFile = false;
      this.hasLoadedFile = true;
    },
    printDRStats() {
      console.log(this.statUtils.toDRStats(this.stats));
    },
    printStats() {
      console.log(this.stats);
    },
  },
});
</script>
