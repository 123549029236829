<script>
import { Line } from "vue-chartjs";
import _ from "lodash";

export default {
  name: "RoomsByHourChart",
  extends: Line,
  props: ["data"],
  methods: {
    drawChart(value) {
      const hours = Object.keys(value);
      const rooms = Object.values(value);

      this.renderChart(
        {
          labels: hours,
          datasets: [
            {
              label: "Rooms",
              data: rooms,
              backgroundColor: "rgba(1, 116, 188, 0.20)",
              borderColor: "rgba(1, 116, 188, 0.50)",
              fill: true,
              stepped: "true",
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Number of rooms at hour of day",
          },
        }
      );
    },
  },
  watch: {
    data(newValue) {
      this.drawChart(newValue);
    },
  },
  mounted() {
    this.drawChart(this.data);
  },
};
</script>