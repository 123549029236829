<script>
import { Line } from "vue-chartjs";
import _ from "lodash";

export default {
  name: "MessageByPlayerTimeChart",
  extends: Line,
  props: ["data"],
  methods: {
    drawChart(value) {
      const playerTime = Object.keys(value);
      const messages = Object.values(value);

      const newMap = [...Array(60).keys()].map((e) => ({
        time: e,
        messages: value[e] ?? 0,
      }));

      console.log(value);
      const test = _.map(newMap, (e, k) => k + "\t" + e.messages);

      console.log(test.join("\n"));

      this.renderChart(
        {
          labels: playerTime,
          datasets: [
            {
              label: "Rooms",
              data: messages,
              backgroundColor: "rgba(1, 116, 188, 0.20)",
              borderColor: "rgba(1, 116, 188, 0.50)",
              fill: true,
              stepped: "true",
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Number of messages grouped by playerTime",
          },
        }
      );
    },
  },
  watch: {
    data(newValue) {
      this.drawChart(newValue);
    },
  },
  mounted() {
    this.drawChart(this.data);
  },
};
</script>