<script>
import { Line } from "vue-chartjs";
import _ from "lodash";

export default {
  name: "RoomsAndUsersLineChart",
  extends: Line,
  props: ["data"],
  methods: {
    drawChart(value) {
      const dates = _.chain(value)
        .map((e) => e.date)
        .toArray()
        .value();
      const users = _.chain(value)
        .map((e) => e.users)
        .toArray()
        .value();
      const rooms = _.chain(value)
        .map((e) => e.rooms)
        .toArray()
        .value();

      this.renderChart(
        {
          labels: dates,
          datasets: [
            {
              label: "Users",
              data: users,
              backgroundColor: "rgba(1, 116, 188, 0.20)",
              borderColor: "rgba(1, 116, 188, 0.50)",
              fill: true,
              stepped: "middle",
            },
            {
              label: "Rooms",
              data: rooms,
              backgroundColor: "rgba(255, 50, 188, 0.20)",
              borderColor: "rgba(255, 50, 188, 0.50)",
              fill: true,
              stepped: "middle",
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Rooms and users by day",
          },
        }
      );
    },
  },
  watch: {
    data(newValue) {
      this.drawChart(newValue);
    },
  },
  mounted() {
    this.drawChart(this.data);
  },
};
</script>