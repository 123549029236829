import _, { forEach } from "lodash";
import moment from 'moment';
import { auth, storage } from '../firebase';

class StatisticUtils {
    roomResults?: RoomResult[];

    async uploadJson() {
        if (!this.roomResults) {
            console.error("Cannot upload empty roomResults");
            return;
        }
        const jsonString = JSON.stringify(this.roomResults);
        const blob = new Blob([jsonString], { type: 'application/json' });

        storage.ref().child("/test.json").put(blob).then(snapshot => console.log("Uploaded stats")).catch(err => console.error("Could not upload stats", err));
    }

    toDRStats(stats: AggregatedStats) {
        const periodStart = moment(stats.filters.periodStart).format("YYYY-MM-DD");
        const periodEnd = moment(stats.filters.periodEnd).format("YYYY-MM-DD");

        const newProgramObj: { [x: string]: number } = {};
        const newProgramsArr: string[] = [];

        let numToTake = 50;
        _.forEach(stats.maps.programTitleMap, e => {
            if (numToTake < 0) return;
            numToTake--;
            newProgramsArr.push(e.title);
            newProgramObj[e.title] = e.value;
        });
        return {
            numberOfUniqueUsers: stats.totals.numUniqUsers,
            numberOfUsers: stats.totals.numOverallUsers,
            numberOfRooms: stats.totals.numRooms,
            averageNumberOfUsersPerRoom: stats.averages.numRealUsers,
            averageNumberOfMessages: stats.averages.numMessages,
            totalNumberOfMessages: stats.totals.numMessages,
            totalWatchTimeInDays: stats.totals.watchTime,
            numberOfVodRooms: stats.maps.roomIsLiveMap.VOD,
            numberOfLiveRooms: stats.maps.roomIsLiveMap.Live,
            periodStart,
            periodEnd,
            mostWatchedProgrammes: newProgramsArr,
        }
    }

    getOneRoomResults(e: any, roomId: string): boolean | RoomResult {
        if (!e.programs) {
            return false;
        }

        // # of messages
        const numMessages = _.keys(e.messages).length;

        const totalMsgLength = _.reduce(
            e.messages,
            (sum, msg) => {
                return sum + msg.content.length;
            },
            0
        );

        const avgMsgLength =
            numMessages === 0 ? 0 : totalMsgLength / numMessages;

        // # of player actions
        const numPlayerPlays = _.reduce(
            e.playerActions,
            (sum, playerAction) => {
                if (playerAction.type === "play") {
                    return ++sum;
                }
                return sum;
            },
            0
        );

        const numPlayerPauses = _.reduce(
            e.playerActions,
            (sum, playerAction) => {
                if (playerAction.type === "pause") {
                    return ++sum;
                }
                return sum;
            },
            0
        );

        const numPlayerSeeks = _.reduce(
            e.playerActions,
            (sum, playerAction) => {
                if (playerAction.type === "seek") {
                    return ++sum;
                }
                return sum;
            },
            0
        );

        const filteredUserhistory = _.pickBy(e.userhistory, e => (e.leaveTime ?? Date.now()) - e.initTime < (1000 * 60 * 120)); // 300 min = 5 hours

        if (_.isEmpty(filteredUserhistory)) {
            return false;
        }

        // # of users
        const numUsers = _.keys(filteredUserhistory).length;

        // # of unique users
        const numUniqUsers = _.keys(_.groupBy(filteredUserhistory, (u) => u.id))
            .length;

        // userHistory average minutes for user
        let totalWatchTime = _.reduce(
            filteredUserhistory,
            (sum, user) => {
                const initTime = user.initTime;
                const leaveTime = user.leaveTime ?? Date.now();

                return leaveTime - initTime + sum;
            },
            0
        );

        const isRealRoom = numUniqUsers > 1;

        totalWatchTime = totalWatchTime / 1000 / 60.0;

        const avgWatchTime = totalWatchTime / numUsers;

        // Room created timestamp
        const createdDate: number = _.minBy(_.values(filteredUserhistory), e => e.initTime)
            .initTime;
        const createdHour = new Date(createdDate).getHours();

        // Room live time (time from first user enter, to last user exit)
        const endedDate: number =
            _.maxBy(_.values(filteredUserhistory), e => e.leaveTime)?.leaveTime ?? -1;
        const totalLiveTime =
            endedDate < 0
                ? -1
                : (endedDate - createdDate) / 1000 / 60;

        // Is room live
        const isLive = _.values(e.programs)[0].isLive;

        // Channel (if live)
        const channel = _.values(e.programs)[0].currentUrnOrChannel;

        // Program title
        // TODO We cannot just take the first one anymore
        const programTitle = _.values(e.programs)[0].programTitle;

        // Array of browsers
        const browserArr = _.values(filteredUserhistory).map((e) => e.browser);

        // Array of os
        const osArr = _.values(filteredUserhistory).map((e) => e.os);

        // Array of user ids
        const userArr = _.values(filteredUserhistory).map((e) => e.id);

        // Number of active users
        const numActiveUsers = _.keys(e.connections).length;

        // Is a bingewatch room
        const numEpisodeSwitches = _.reduce(
            e.playerActions,
            (sum, playerAction) => {
                if (playerAction.type === "programswitch") {
                    return ++sum;
                }
                return sum;
            },
            0
        );

        // TODO remove user and other stuff from this we only want message and timestamp
        // Determine when a message was sent based on the absolute timestamps
        const mappedMessages = _.chain(e.messages).filter(m => typeof m.timestamp !== "undefined").map(m => {
            // If we have no player actions, approximate using the room's createdDate
            if (typeof e.playerActions === "undefined") {
                return { content: m.content, playerTime: (m.timestamp - createdDate) / 10000 };
            }

            // Get latest playerAction (first where message timestamp is larger)
            let curPa = _.findLast(e.playerActions, p => m.timestamp > p.timestamp);

            // Message is LOWER than ALL player actions
            // We don't know when it was sent, so use the created date
            if (!curPa) {
                //curPa = e.playerActions[Object.keys(e.playerActions)[0]];
                return { content: m.content, playerTime: (m.timestamp - createdDate) / 10000 };
            }

            // Messages sent while video was paused, so this is the vidtime the message was sent at
            if (curPa.type === "pause") {
                return { content: m.content, playerTime: curPa.playerState.timestamp };
            }

            // Calculate the timestamp relative to the video
            // NB playerTime is in seconds
            return { content: m.content, playerTime: (m.timestamp - curPa.timestamp) / 10000 + (curPa.playerState.timestamp) }
        }).value();

        return {
            roomId: roomId,
            roomInfo: {
                createdDate,
                createdHour,
                endedDate,
                totalLiveTime,
                isLive,
                channel,
                programTitle,
                isRealRoom,
            },
            averages: {
                msgLength: avgMsgLength,
                watchTime: avgWatchTime,
            },
            totals: {
                msgLength: totalMsgLength,
                watchTime: totalWatchTime,
                numPlayerPlays,
                numPlayerPauses,
                numPlayerSeeks,
                numUsers,
                numUniqUsers,
                numMessages,
                numEpisodeSwitches,
            },
            arrays: {
                browserArr: browserArr,
                osArr: osArr,
                userIdArr: userArr,
                mappedMessageArr: mappedMessages,
            }
        }
    }

    parseRawData(data: { rooms: any[] }) {
        // Filter away faulty rooms
        this.roomResults = _.map(data.rooms, this.getOneRoomResults).filter(e => typeof e !== "boolean") as RoomResult[];
    }

    getAggregatedStatistics(dateLowerBound: Date, dateUpperBound: Date, programTitle: string): AggregatedStats | undefined {
        if (!this.roomResults) {
            console.error("Cannot calculate aggregated statistics before having parsed raw data");
            return;
        }

        const numRoomsWithFaultyId = _.sumBy(this.roomResults, e => e.roomId.length > 6 ? 1 : 0);
        console.log("Faulty rooms", numRoomsWithFaultyId);


        const roomResult = this.roomResults.filter(
            e => e.roomInfo.endedDate > dateLowerBound.getTime()
                && e.roomInfo.endedDate < dateUpperBound.getTime());
        // && e.roomInfo.programTitle.startsWith(programTitle));
        const numRooms = roomResult.length;

        // Total number of real rooms
        const totalNumRealRooms = _.sumBy(roomResult, e => e.roomInfo.isRealRoom ? 1 : 0);

        // Average message length
        const totalRoomsWithMessages = _.chain(roomResult)
            .filter((e) => e.totals.numMessages !== 0)
            .value().length;
        const averageMessageLength =
            _.sumBy(roomResult, e => e.averages.msgLength) / totalRoomsWithMessages;

        const totalPlayerPlays = _.sumBy(roomResult, e => e.totals.numPlayerPlays)
        const totalPlayerPauses = _.sumBy(roomResult, e => e.totals.numPlayerPauses)
        const totalPlayerSeeks = _.sumBy(roomResult, e => e.totals.numPlayerSeeks)

        const avgPlayerPlays = totalPlayerPlays / numRooms;
        const avgPlayerPauses = totalPlayerPauses / numRooms
        const avgPlayerSeeks = totalPlayerSeeks / numRooms;

        const avgNumUsers = _.sumBy(roomResult, e => e.totals.numUsers) / numRooms;
        const avgNumUniqUsers = _.sumBy(roomResult, e => e.totals.numUniqUsers) / numRooms;
        const totalNumUniqUsers = _.chain(roomResult)
            .map((e) => e.arrays.userIdArr)
            .flatten()
            .uniq()
            .value().length;

        // Contains duplicates
        const totalNumOverallUsers = _.sumBy(roomResult, e => e.totals.numUsers);

        const totalNumUniqRealUsers = _.chain(roomResult)
            .filter(e => e.roomInfo.isRealRoom)
            .sumBy(e => e.totals.numUniqUsers)
            .value();

        const avgNumRealUsers = totalNumUniqRealUsers / totalNumRealRooms;

        // Total overall watch time (total user accumulated leavetime - inittime)
        const totalWatchTime = _.sumBy(roomResult, e => e.totals.watchTime) / 60 / 24; // IN days

        // Average time that users are in a room
        const avgWatchTime = _.sumBy(roomResult, e => e.averages.watchTime) / numRooms;

        // Number of rooms grouped by average watch time
        const watchTimeMap = _.chain(roomResult)
            .filter(e => e.roomInfo.isRealRoom && e.averages.watchTime >= 0)
            .map(e => Math.round(e.averages.watchTime))
            .countBy(e => e)
            .value();


        // How many rooms and (UNIQUE) users there are, divided into dates
        const roomsByDayMap = _.chain(roomResult)
            .groupBy(e => moment(new Date(e.roomInfo.createdDate)).format('YYYY-MM-DD'))
            .map((dateArr, date) => ({ date: date, users: _.sumBy(dateArr, e => e.totals.numUniqUsers), rooms: dateArr.length }))
            .sortBy(e => e.date)
            // .map(e => { console.log(moment(e.date, "YYYY-MM-DD").format("DD-MM-YYYY"), e.rooms, e.users); return e; }) // Only for printing
            .value();

        // Helper function to get top 10 programmes of a given date
        // const test = _.chain(roomResult)
        //     .filter(e => moment(new Date(e.roomCreatedDate)).format("DD-MM-YYYY") === "26-03-2021")
        //     .map(e => e.programTitle)
        //     .countBy(e => e)
        //     .map((count, title) => ({ count, title }))
        //     .orderBy(e => e.count, ["desc"])
        //     .take(10)
        //     .value();
        // console.log(test);

        // Average time that unique users are in a (REAL) room
        const avgRealWatchTime = _.chain(roomResult).filter(e => e.roomInfo.isRealRoom).sumBy(e => e.averages.watchTime).value() / totalNumRealRooms;

        const roomCreatedHourMap = _.chain(roomResult)
            .groupBy(e => e.roomInfo.createdHour)
            .mapValues((e) => e.length)
            .value();

        const roomGraphHourArr = _.times(24, e => 0).map(
            (e, i) => roomCreatedHourMap[i] ?? 0
        );

        const avgRoomLiveTime =
            _.sumBy(roomResult, e => e.roomInfo.totalLiveTime) / numRooms;


        const liveMapRaw = _.countBy(roomResult, e => e.roomInfo.isLive);
        const roomIsLiveMap = {
            Live: liveMapRaw["true"],
            VOD: liveMapRaw["false"],
        };

        const channelMap = _.chain(roomResult)
            .filter((e) => (e.roomInfo.channel ? e.roomInfo.channel.length > 0 : false))
            .groupBy(e => e.roomInfo.channel)
            .mapValues((e) => e.length)
            .value();

        const programTitleMapUnsorted = _.chain(roomResult)
            .filter(e => !e.roomInfo.isLive)
            .groupBy(e => e.roomInfo.programTitle)
            .map((e, k) => ({ title: k, value: e.length }))
            .value();

        const programTitleMap = _.orderBy(programTitleMapUnsorted, e => e.value, ["desc"])

        const browserMap = _.chain(roomResult)
            .map((e) => e.arrays.browserArr)
            .flatMap()
            .groupBy((e) => e)
            .mapValues((e) => e.length)
            .value();

        const osMap = _.chain(roomResult)
            .map((e) => e.arrays.osArr)
            .flatMap()
            .groupBy((e) => e)
            .mapValues((e) => e.length)
            .value();


        // Average messages in all REAL rooms
        const averageNumMessages =
            _.chain(roomResult)
                .filter(e => e.roomInfo.isRealRoom)
                .sumBy(e => e.totals.numMessages).value() / totalNumRealRooms;

        // Total number of messages in ALL rooms
        const totalNumMessages = _.sumBy(roomResult, e => e.totals.numMessages);

        // Total number of episode switches (binge watch)
        const totalEpisodeSwitches = _.sumBy(roomResult, e => e.totals.numEpisodeSwitches);

        // Number of rooms that switched episodes at least once
        const totalBingewatchRooms = _.sumBy(roomResult, e => e.totals.numEpisodeSwitches > 0 ? 1 : 0);

        const messagesByPlayerTimeMap = _.chain(roomResult)
            .filter(e => e.averages.watchTime >= 0)
            .map(e => e.arrays.mappedMessageArr)
            .flattenDeep()
            .filter(m => m.playerTime > 0)
            .map(m => Math.round(m.playerTime / 60))
            .countBy(e => e)
            .value();

        // const msgTest = _.chain(roomResult)
        //     .filter(e => e.averages.watchTime >= 0)
        //     .map(e => e.arrays.mappedMessageArr.filter(m => { if (!(m.playerTime >= 0)) console.log(m); return m.playerTime >= 0 }).length)
        //     .sum()
        //     .value();

        // console.log("LENGTH", msgTest);


        const messageLogByMinuteMap = _.mapValues(_.chain(roomResult)
            .filter(e => e.averages.watchTime >= 0)
            .map(e => e.arrays.mappedMessageArr)
            .flattenDeep()
            .filter(m => m.playerTime > 0)
            .map(m => ({ playerTime: m.playerTime, content: m.content, rounded: Math.round(m.playerTime / 60) }))
            .groupBy(m => m.rounded).value()
            , list => list.map(m => moment.utc(m.playerTime * 1000).format('HH:mm:ss') + " - " + m.content));


        return {
            filters: {
                periodStart: dateLowerBound,
                periodEnd: dateUpperBound,
                programSearch: programTitle,
            },
            averages: {
                roomLiveTime: avgRoomLiveTime,
                watchTime: avgWatchTime,
                messageLength: averageMessageLength,
                realWatchTime: avgRealWatchTime,
                numMessages: averageNumMessages,
                numPlayerPlays: avgPlayerPlays,
                numPlayerPauses: avgPlayerPauses,
                numPlayerSeeks: avgPlayerSeeks,
                numUsers: avgNumUsers,
                numUniqUsers: avgNumUniqUsers,
                numRealUsers: avgNumRealUsers,
            },
            totals: {
                watchTime: totalWatchTime,
                episodeSwitches: totalEpisodeSwitches,
                numBingewatchRooms: totalBingewatchRooms,
                numRooms: numRooms,
                numRoomsWithMessages: totalRoomsWithMessages,
                numMessages: totalNumMessages,
                numPlayerPlays: totalPlayerPlays,
                numPlayerPauses: totalPlayerPauses,
                numPlayerSeeks: totalPlayerSeeks,
                numRealRooms: totalNumRealRooms,
                numUniqRealUsers: totalNumUniqRealUsers,
                numUniqUsers: totalNumUniqUsers,
                numOverallUsers: totalNumOverallUsers,
            },
            maps: {
                browserMap,
                osMap,
                programTitleMap,
                channelMap,
                roomIsLiveMap,
                watchTimeMap,
                roomsByDayMap,
                messagesByPlayerTimeMap,
                messageLogByMinuteMap,
                roomCreatedHourMap,
                roomGraphHourArr,
            },
        };
    }
}

export default StatisticUtils;